@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Noto+Sans+JP:wght@200;300;400;600&family=Zen+Old+Mincho:wght@400&display=swap');

.pc-on {
  display: block;
}

.sp-on {
  display: none;
}

a {
  text-decoration: none;
  transition: all 0.5s;
  color: #333;
  img {
    transition: opacity 0.5s;
    backface-visibility: hidden; 
  }
  &:hover {
    opacity: 0.7;
    text-decoration: none;
    img {
      opacity: 0.7;
    }
  }
}

@include sp-screen {
  
  .pc-on {
    display: none;
  }

  .sp-on {
    display: block;
  }
    
  input[type="text"],
  input[type="button"],
  input[type="email"],
  input[type="submit"],
  input[type="password"],
  textarea {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -webkit-appearance: none;
      max-width: 100%;
      border-radius: 0;
  }
  
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.7;
  font-weight: 400;
  background: #f8f7f7;
  word-break: break-all;
  @include sp-screen {
    font-size: 14px;
    font-weight: 300;
  }
}
@charset "UTF-8";

@include sp-screen {}

.news {
  background: #fff;
  padding: 24px;
  font-weight: 300;
  font-size: 12px;
  li {
    border-bottom: 1px dotted #bbb;
    padding: 0 0 8px;
    margin: 0 0 8px;
    &:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
  &__wrap {
    margin: -20px auto 100px;
    position: relative;
    background: #fff;
    .more {
      width: max-content;
      color: #9bd0e2;
      font-size: 15px;
      border-radius: 14px;
      cursor: pointer;
      font-family: 'Abel', sans-serif;
      letter-spacing: 0.1rem;
      margin: -20px auto 0;
      padding: 0 0 10px 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }
   .news__date {
    font-size: 15px;
    font-family: 'Abel', sans-serif;
    line-height: 1;
    margin: 0 0 8px;
    letter-spacing: 0.05rem;
    padding: 0 10px 0 0;
    color: #9bd0e2;
   }
}

.caution {
    background: url(../img/caution.svg);
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto 8px;
}
strong {
    font-weight: 600;
}
em {
    font-weight: 600;
    color: #e9519b;
}
.c-icon {
    width: 110px;
    height: 110px;
    border-radius: 70px;
    overflow: hidden;
    margin: 0 auto 24px;
    img {
        width: 100%;
        margin: 0 auto 16px;
        height: 100%;
        object-fit: cover;
    }
}

.modal-open{
    top: 50%;
    left: 50%;
    font-size: 16px;
    font-weight: bold;
    width: 300px;
    height: 60px;
    color: #fff;
    background: #000;
    border: none;
    cursor: pointer;
  }
  
  .modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    backdrop-filter: blur(70px);
    -webkit-backdrop-filter: blur(70px);
    background: rgba(240, 240, 240, 0.4);
    padding: 40px 20px;
    overflow: auto;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    box-sizing: border-box;
    @include sp-screen {
      padding: 40px 10px;
      box-sizing: border-box;
      overflow-x: hidden;
    }
  }
  
  .modal:before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-left: -0.2em;
  }
  
  .modal.is-active{
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
  
  .modal-container{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 800px;
    width: 97%;
  }
  
  .modal-close{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    color: #000;
    cursor: pointer;
    font-size: 28px;
    cursor: pointer;
  }
  
  .modal-content{
    background: #fff;
    text-align: left;
    line-height: 1.8;
    padding: 16px 16px 0;
    border-radius: 8px;
    box-shadow: 6px 6px 10px #dcdcdc;
    @include sp-screen {
      padding: 16px 16px 0;
    }
    img {
        width: 100%;
        display: block;
    }
    figure {
      margin: 0 auto;
      padding: 0 0 16px 0;
    }
    p {
      margin: 0 auto;
      padding: 0 0 16px 0;
    }
  }

  
.l-narrow {
    margin: 0 auto;
    width: 70%;
}

.more-btn {
    width: max-content;
    padding: 3px 40px 18px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 18px;
    text-align: center;
    font-family: 'Abel', sans-serif;
    margin: 40px 0 0 auto;
    letter-spacing: 0.1rem;
    line-height: 1;
    font-family: 'Zen Old Mincho', serif;
    @include sp-screen {
        margin: 32px 0 0 auto;
    }
    &:after {
        content: '';
        width: 30px;
        height: 6px;
        border-bottom: solid 1px #000;
        border-right: solid 1px;
        transform: skew(45deg);
        position: absolute;
        right: 0;
        bottom: 22px;
      }
}

.bg-white {
    background: #fff;
    padding: 20px 16px 24px;
    border-radius: 8px;
}

.links {
  li {
    a {
      border-bottom: 1px solid;
      margin: 0 0 8px 0;
      display: inline-block;
    }
    .description {
      display: block;
      font-size: 12px;
      font-weight: 200;
      margin: 0 0 16px 8px;
      line-height: 1.55;
    }
  }
}

.mincho {

  font-family: 'Zen Old Mincho', serif;
}

.banner {
  width: 200px;
  height: 40px;
  overflow: hidden;
  display: block;
  margin: 60px auto 0;
}

.filterDiv {
  display: none;
}
.show {
  display: block;
}

#searchButtonnBlock .btn {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}
#searchButtonnBlock {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 0 16px;
    .tag__txt {
      margin: 0 0 12px;
    }
}
#searchButtonnBlock button.btn.onActive {
  .tag__txt {
    background: #6fb9d2;
    color: #fff;
  }
}

.nav-links,.pagination {
  display: flex;
    margin: 40px auto 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    a {
      font-size: 13px;
      margin: 12px 8px 0 0;
      padding: 4px 12px;
      background: #fff;
      border-radius: 4px;
      color: #4f4f4f;
      border: 1px solid #bbb;
      display: inline-block;
      @include sp-screen {
        font-size: 11px;
      }
    }
    .current {
      font-size: 13px;
        margin: 12px 8px 0 0;
        padding: 4px 12px;
        background: #4f4f4f;
        border: 1px solid #4f4f4f;
        border-radius: 4px;
        color: #ffffff;
        @include sp-screen {
          font-size: 11px;
        }
    }
}
.page-numbers {
  &.dots {
    display: block;
    margin: 12px 8px 0 0;
  }
}
form.sort {
  display: flex;
    margin: 0 0 40px;
    @include pc-screen {
      width: 60%;
      margin: 0 auto 40px;
    }
    select {
      margin: 12px 8px 0 0;
      padding: 12px 8px;
      background: #fff;
      border-radius: 4px;
      color: #4f4f4f;
      border: 1px solid #bbb;
      display: inline-block;
      width: 70%;
      font-size: 15px;
    }
    button {
      margin: 12px 8px 0 0;
      padding: 4px;
      background: #4f4f4f;
      border-radius: 4px;
      color: #ffffff;
      appearance: none;
      width: 30%;
      border: none;
      cursor: pointer;
      outline: none;
      appearance: none;
      display: block;
      font-size: 13px;
      @include pc-screen {
        max-width: 100px;
      }
    }
}

.line1-center-txt {
  p {
    margin-inline: auto;
    max-inline-size: max-content;
    margin: 0 auto;
  }
}


.single .contents__main {

  blockquote.wp-block-quote {
    padding: 16px;
    background: #Fff;
    border-radius: 4px;
    color: #666;
    p {
      margin: 0 auto;
    }
    cite {
      font-size: 11px;
      color: #6fb9d2;
    }
  }
  ul {
    li {
      list-style: inside;
    }
  }
  a {
    color: #6fb9d2;
    border-bottom: 1px solid;
  }
}
@charset "UTF-8";

@include sp-screen {}

header {
    h1  {
        padding: 32px 0;
        text-align: center;
        background: #fff;
        font-family: 'Abel', sans-serif;
        letter-spacing: 0.1rem;
        font-size: 18px;
        &.cover {
            font-size: 24px;
            background: url(/assets/img/3.jpg) no-repeat center center;
            background-size: cover;
            height: 40vh;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                color: #fff;
            }
        }
        @include sp-screen {
            padding: 24px 0;
            font-size: 14px;
        }
    }
    margin: 0 0 40px;
  }

main {
    max-width: 800px;
    margin: 0 auto;
    &.single {
        margin: 0 auto 32px;
    }
    .wrapper {
        padding: 0 24px;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;
        @include sp-screen {
            grid-template-columns: repeat(3, 1fr);
        }
        .sensitive {
            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                backdrop-filter: blur(15px);
                -webkit-backdrop-filter: blur(15px);
                background: rgba(255, 255, 255, 0.4);
                box-sizing: border-box;
            }
            &::after {
                content: "\f070";
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 3;
                width: 100%;
                height: 100%;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
        }
        &__txt {
            li {
                a {
                    display: flex;
                    margin: 0 0 16px;
                    border-bottom: 1px solid #bbb;
                    padding: 0 0 16px;
                    align-items: center;
                    background: #fff;
                    padding: 16px;
                    border: 0;
                    border-radius: 8px;
                    @include sp-screen {
                       display: block;
                    }
                }
                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }
            }
            &--wrap {
                width: 100%;
                @include pc-screen {
                    display: flex;
                    align-items: center;
                }
                @include sp-screen {
                    display: flex;
                    align-items: center;
                    margin: 0 0 8px 0;
                }
            }
            &--description {
                font-weight: 200;
                font-size: 12px;
                margin: 8px 0;
                line-height: 1.9;
            }
            &--thumb {
                display: block;
                height: 100%;
                width: 0;
                padding: 15% 11%;
                overflow: hidden;
                margin: 0 24px 0 0;
                position: relative;
                @include sp-screen {
                    margin: 0 12px 0 0;
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                }
            }
            &--ttl {
                font-family: 'Zen Old Mincho', serif;
                font-size: 22px;
                @include sp-screen {
                    font-size: 20px;
                    // margin: 0 0 8px 0;
                    line-height: 1.55;
                }
            }
            .tag {
                @include sp-screen {
                    margin: 4px 0 0;
                }
                &__txt {
                    font-size: 13px;
                    margin: 12px 8px 0 0;
                    padding: 8px 24px;
                    background: #fff;
                    border-radius: 38px;
                    color: #6fb9d2;
                    border: 1px solid #6fb9d2;
                    display: inline-block;
                    @include sp-screen {
                        font-size: 11px;
                        margin: 4px 4px 0 0;
                        padding: 4px 10px;
                    }
                }
                &__list 
                li {
                    font-size: 13px;
                    margin: 12px 8px 0 0;
                    padding: 8px 24px;
                    background: #9bd0e2;
                    border-radius: 38px;
                    color: #ffffff; 
                    @include sp-screen {
                        font-size: 11px;
                        margin: 4px 4px 0 0;
                        padding: 4px 10px;
                    }
                }
            }
            &.series {
                margin: 0 0 16px;
                li {
                    position: relative;
                    margin: 0 0 16px 0;
                    display: flex;
                    align-items: baseline;
                    flex-wrap: wrap;
                    a {
                        display: flex;
                        align-items: center;
                        border: 0;
                        margin: 0 8px 0 0;
                        color: #4fafcf;
                        font-size: 18px;
                        background: none;
                        padding: 0;
                        border-radius: 0;
                        font-family: 'Zen Old Mincho', serif;
                        line-height: 1.4;
                        @include sp-screen {
                            align-items: flex-start;
                        }
                        span {
                            font-family: 'Noto Sans JP', sans-serif;
                            font-size: 10px;
                            color: #fff;
                            background: #6fb9d2;
                            font-weight: 400;
                            padding: 2px 8px;
                            position: relative;
                            top: -3px;
                            margin: 0 4px;
                            border-radius: 4px;
                        }
                    }
                    .description {
                        font-family: 'Noto Sans JP', sans-serif;
                        font-size: 12px;
                        color: #333;
                        font-weight: 200;
                        margin: 4px 0 0 0;
                    }
                }
                .series__ttl {
                    font-size: 18px;
                    font-family: 'Zen Old Mincho', serif;
                    margin: 0 8px 0 0;
                    span {
                        font-family: 'Noto Sans JP', sans-serif;
                        font-size: 10px;
                        color: #fff;
                        background: #6fb9d2;
                        font-weight: 400;
                        padding: 2px 8px;
                        position: relative;
                        top: -3px;
                        margin: 0 4px;
                        border-radius: 4px;
                    }
                }
                .series__wrap {
                    display: flex;
                    align-items: baseline;
                    margin: 16px 0;
                    flex-wrap: wrap;
                }
                .series__list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    flex-shrink: 1;
                    margin: 8px 8px 0 0;
                    li {
                        margin: 0 8px 0 0;
                        a {
                            padding: 0;
                            margin: 0;
                            line-height: 1.7;
                        }
                    }
                }
            }
        }
        .card {
            width: 100%;
            padding-top: 100%;
            height: 0;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            @include sp-screen {
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}


.header__nav {
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background: rgba(255,255,255,.2);
    transition: ease .4s;
    display: block;
    position: fixed;
    z-index: 9;
  }
  
  .nav-items {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 60px;
  }
  
  .nav-items__item a {
    color: black;
    width: 100%;
    display: block;
    margin-bottom: 24px;
    text-align: left;
    font-family: 'Zen Old Mincho', serif;
    font-size: 24px;
    letter-spacing: 0.05rem;
  }
  
  .nav-items__item:last-child a {
    margin-bottom: 0;
  }
  
  
  .header__hamburger {
    width: 48px;
    height: 48px;
  
  }
  
  .hamburger {
    background-color: transparent;
    border-color: transparent;
    z-index: 9999;
    position: fixed;
    top: 10px;
    right: 10px;
    bottom: auto;
    cursor: pointer;
  }
  
  
  
  .hamburger span {
    width: 100%;
    height: 1px;
    background-color: #000;
    position: relative;
    transition: ease .4s;
    display: block;
  }
  
  .hamburger span:nth-child(1) {
    top: 0;
  }
  
  .hamburger span:nth-child(2) {
    margin: 8px 0;
  }
  
  .hamburger span:nth-child(3) {
    top: 0;
  }
  
  .header__nav.active {
    transform: translateX(0);
  }
  
  .hamburger.active span:nth-child(1) {
    top: 13px;
    transform: rotate(45deg);
  }
  
  .hamburger.active span:nth-child(2) {
    opacity: 0;
  
  }
  
  .hamburger.active span:nth-child(3) {
    top: -6px;
    transform: rotate(-45deg);
  } 

footer {
    padding: 24px 0;
    text-align: center;
    font-family: 'Abel', sans-serif;
}

.contents { 
    margin: 0 0 60px;
    &__wrap {
        margin: 0 0 100px 0;
    }
    .page__ttl {
        font-weight: 400;
        font-size: 24px;
        letter-spacing: 0.05rem;
        margin: 0 0 24px;
        text-align: center;
        font-family: 'Zen Old Mincho', serif;
        &--description {
            font-size: 13px;
            text-align: center;
            font-family: 'Noto Sans JP', sans-serif;
            margin: -8px 0 24px;
            font-weight: 200;
        }
    }
    .page__subttl {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0;
        margin: 0 0 24px;
        text-align: left;
        border-left: 3px solid;
        padding-left: 15px;
        &--description {
            border-bottom: 1px dotted #bbb;
            padding: 8px 0;
            border-top: 1px dotted #bbb;
            display: block;
            margin: 0 0 24px;
            font-weight: 200;
            p {
                font-size: 13px;
                line-height: 1.5;
                margin-inline: auto;
                max-inline-size: max-content;
            }
        }
    }
    &__main {
        line-height: 2;
        font-size: 16px;
        letter-spacing: 0.02rem;
        font-weight: 400;
        img {
            width: 100%;
            margin: 0 0 16px;
        }
        p {
            margin: 0 0 16px;
            @include sp-screen {
                font-size: 14px;
                font-weight: 300;
            }
        }
        h2 {
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 16px 0;
            border-left: 4px solid;
            padding: 0 0 0 12px;
            line-height: 1.55;
        }
        h3 {
            font-size: 22px;
            font-weight: 600;
            margin: 0 0 16px;
        }
        h4 {
            font-size: 18px;
            font-weight: 400;
            margin: 0 0 16px;
            border-bottom: 1px dotted;
            padding: 0 0 4px 0;
        }
    }
    .category {
        display: flex;
        align-items: flex-start;
        margin: 0 0 24px;
        ul {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .tag {
        display: flex;
        &__ttl {
            font-family: 'Abel', sans-serif;
            font-size: 18px;
            min-width: 56px;
            letter-spacing: 0.05rem;
            @include sp-screen {
                min-width: 72px;
            }
        }
        &__txt {
            a {
                margin: 0 0 8px 8px;
                padding: 8px 24px;
                border-radius: 38px;
                font-size: 13px;
                border: 1px solid #6fb9d2;
                display: inline-block;
                background: #fff;
                color: #6fb9d2;
                display: inline-block;
            }
        }
        &__list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                a {
                    font-size: 13px;
                    margin: 0 0 8px 8px;
                    padding: 8px 24px;
                    background: #9bd0e2;
                    border-radius: 38px;
                    color: #ffffff;
                    display: inline-block;
                }
            }
            &--min {
                li {
                    font-size: 11px;
                    margin: 0 8px 8px 0;
                    padding: 4px 10px;
                }
            }
        }
    }
}
.single {
    .contents {
        &__main {
            margin: 0 0 40px;
            border-bottom: 1px solid #bbb;
            padding: 0 0 40px;
        }
    }
}
.cards__blog {
    grid-template-columns: none;
    &.cards {    
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        @include sp-screen {
            grid-template-columns: repeat(2, 1fr);
        }
        .card {
            padding-top: 100%;
            background: #fff;
            min-height: 90px;
            &__date {
                font-size: 18px;
                font-family: 'Abel', sans-serif;
                line-height: 1;
                margin: 0 0 8px;
                letter-spacing: 0.05rem;
            }
            img {
                width: 100%;
                height: 50%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                bottom: auto;
            }
            &__txt {
                z-index: 1;
                bottom: 0;
                height: 48%;
                font-size: 14px;
                padding: 16px;
                box-sizing: border-box;
                overflow: hidden;
                position: absolute;
                margin: auto;
                @include sp-screen {                
                    padding: 12px;
                }
                p {
                    overflow: hidden;
                    display: inline-flex;
                    line-height: 1.7;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    @include sp-screen {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        line-height: 1.55;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    &--top {
        li {
            &:nth-child(3) {
                @include sp-screen {                 
                    display: none;
                }
            }
        }
    }
}
